// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/ErrorBoundary.tsx"
);
import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { Component, ErrorInfo, ReactNode } from "react";
import MainContainer from "./components/MainContainer";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);

    setTimeout(() => {
      this.goToHome();
    }, 3000);
  }

  goToHome = () => {
    window.location.href = window.location.origin;
  };

  render() {
    if (this.state.hasError) {
      return (
        <MainContainer headerTitle="">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "16px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "40px",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#2d3748",
                  marginBottom: "8px",
                }}
              >
                {"Something went wrong"}
              </h2>
              <p
                style={{
                  fontSize: "18px",
                  color: "#718096",
                  marginBottom: "24px",
                }}
              >
                {`We're sorry, but something went wrong. You'll be redirected to the home page.`}
              </p>
              <button
                onClick={this.goToHome}
                style={{
                  backgroundColor: "#48bb78",
                  color: "white",
                  fontSize: "18px",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#38a169")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#48bb78")
                }
              >
                {`Return to Home`}
              </button>
            </div>
          </div>
        </MainContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
