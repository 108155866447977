// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/redux/root-reducer.ts"
);
import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { combineReducers } from "redux";
import onboarding from "~/redux/slice/onboarding-slice";
import user from "~/redux/slice/users-slice";

const rootReducer = combineReducers({
  user,
  onboarding,
});

export default rootReducer;
