// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Auth0/Auth0ProviderWrapper.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Auth0/Auth0ProviderWrapper.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

// components/Auth0Provider.tsx

import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useLoaderData } from "@remix-run/react";
const Auth0ProviderWrapper = ({
  children
}) => {
  _s();
  const data = useLoaderData();
  const {
    domain,
    clientId,
    redirectUri,
    audience
  } = data.auth0;
  return <Auth0Provider domain={domain} clientId={clientId} authorizationParams={{
    redirect_uri: redirectUri,
    audience: audience
  }}>
      {children}
    </Auth0Provider>;
};
_s(Auth0ProviderWrapper, "5thj+e1edPyRpKif1JmVRC6KArE=", false, function () {
  return [useLoaderData];
});
_c = Auth0ProviderWrapper;
export default Auth0ProviderWrapper;
var _c;
$RefreshReg$(_c, "Auth0ProviderWrapper");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;