// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/useHeap.tsx"
);
import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { useEffect } from "react";

export function useHeap(heapAccountId: string) {
  useEffect(() => {
    window.heapReadyCb = window.heapReadyCb || [];
    window.heap = window.heap || [];

    window.heap.load = function (e: string, t?: any) {
      window.heap.envId = e;
      window.heap.clientConfig = t || {};
      window.heap.clientConfig.shouldFetchServerConfig = false;

      const a = document.createElement("script");
      a.type = "text/javascript";
      a.async = true;
      a.src = `https://cdn.us.heap-api.com/config/${e}/heap_config.js`;

      const r = document.getElementsByTagName("script")[0];
      if (r.parentNode) {
        r.parentNode.insertBefore(a, r);
      }

      const n = [
        "init",
        "startTracking",
        "stopTracking",
        "track",
        "resetIdentity",
        "identify",
        "getSessionId",
        "getUserId",
        "getIdentity",
        "addUserProperties",
        "addEventProperties",
        "removeEventProperty",
        "clearEventProperties",
        "addAccountProperties",
        "addAdapter",
        "addTransformer",
        "addTransformerFn",
        "onReady",
        "addPageviewProperties",
        "removePageviewProperty",
        "clearPageviewProperties",
        "trackPageview",
      ];

      const i = function (e: string) {
        return function (...args: any[]) {
          window.heapReadyCb!.push({
            name: e,
            fn: function () {
              (window.heap as any)[e] &&
                (window.heap as any)[e].apply(window.heap, args);
            },
          });
        };
      };

      for (let p = 0; p < n.length; p++) window.heap[n[p]] = i(n[p]);
    };

    window.heap.load(heapAccountId);
  }, [heapAccountId]);
}
