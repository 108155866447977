// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, json, useLoaderData, useRouteError } from "@remix-run/react";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import stylesheet from "~/tailwind.css";
import Auth0ProviderWrapper from "./components/Auth0/Auth0ProviderWrapper";
import ErrorBoundaryComponent from "./ErrorBoundary";
import { useHeap } from "./hooks/useHeap";
import { store } from "./redux/store";
import ErrorPage from "./routes/user+/error-page";
import NotFoundPage from "./routes/user+/not-found-page";
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}, {
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
}];
export async function loader() {
  return json({
    baseApiUrl: process.env.API_URL || "",
    hvacRedirectUrl: process.env.HVAC_REDIRECT_URI || "",
    clientApiUrl: process.env.CLIENT_API_URL || "",
    onboardingRedirectUrl: process.env.ONBOARDING_REDIRECT_URL || "",
    onboardingUpdateRedirectUrl: process.env.ONBOARDING_UPDATE_REDIRECT_URL || "",
    auth0: {
      domain: process.env.AUTH0_DOMAIN || "",
      clientId: process.env.AUTH0_CLIENT_ID || "",
      redirectUri: process.env.AUTH0_REDIRECT_URI || "",
      audience: process.env.AUTH0_AUDIENCE || ""
    },
    env: {
      env: process.env.ENV || "",
      localUrl: process.env.WEB_APP_LOCAL_URL || "",
      stagingUrl: process.env.WEB_APP_STAGING_URL || "",
      sandboxUrl: process.env.WEB_APP_SANDBOX_URL || "",
      port: process.env.WEB_APP_PORT || "3000"
    },
    firebaseConfig: {
      apiKey: process.env.FIREBASE_API_KEY || "",
      authDomain: process.env.FIREBASE_AUTH_DOMAIN || "",
      projectId: process.env.FIREBASE_PROJECT_ID || "",
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET || "",
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "",
      appId: process.env.FIREBASE_APP_ID || "",
      measurementId: process.env.FIREBASE_MEASUREMENT_ID || ""
    },
    analytics: {
      heapAccountId: process.env.HEAP_ACCOUNT_ID || "",
      googleId: process.env.GOOGLE_ANALYTICS_ID || ""
    }
  });
}
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  return <html lang="en">
      <head>
        <title>WS</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Scripts />
        {isRouteErrorResponse(error) ? <NotFoundPage /> : <ErrorPage />}
      </body>
    </html>;
}
_s(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export default function App() {
  _s2();
  const data = useLoaderData();
  useHeap(data.analytics.heapAccountId);
  return <ErrorBoundaryComponent>
      <Provider store={store}>
        <ConfigProvider theme={{
        token: {
          colorPrimary: "#10BC21",
          borderRadius: 2
        }
      }}>

          <Auth0ProviderWrapper>
            <html lang="en">
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
                {" "}
                <meta charSet="utf-8" />
                <Meta />
                <Links />
              </head>
              <body>
                <Outlet />
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
                <script dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(data)}`
              }} />

                <script async src={`https://www.googletagmanager.com/gtag/js?id=${data.analytics.googleId}`}>
                </script>
                <script dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${data.analytics.googleId}');
            `
              }} />


                <Scripts />
              </body>
            </html>
          </Auth0ProviderWrapper>
        </ConfigProvider>
      </Provider>
    </ErrorBoundaryComponent>;
}
_s2(App, "5fsA5EyVZO0OGHEUtJSdUlgnrcw=", false, function () {
  return [useLoaderData, useHeap];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;